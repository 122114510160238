<script setup>
import { computed, watch, ref, nextTick } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const confirm = computed(() => store.state.common.confirm)
</script>

<template>
    <div class="modal" :class="{ active: confirm.showModal }">
        <div class="modal__window" style="width: 450rem;">
            <div
                class="modal__close"
                @click="confirm.onClose ? confirm.onClose(confirm) : confirm.showModal = false"
            >
                <span></span>
                <span></span>
            </div>
            <div class="modal__head">
                <div class="modal__title">
                    {{ confirm.title }}
                </div>
            </div>
            <div class="modal__content">
                <div class="modal__inner">
                    <div class="text" v-html="confirm.text"></div>
                </div>
                <div class="modal__footer">
                    <div
                        :class="`btn btn--${button.btnType} mr-10`"
                        v-for="(button, index) in confirm.buttons"
                        :key="index"
                        @click="button.callback(confirm)"
                    >
                        <template v-if="button.btnType === 'transparent'"> 
                            <div class="inner">{{ button.text }}</div>{{ button.text }}
                        </template>
                        <template v-else> 
                            {{ button.text }}
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.icon {
    width: 28rem; 
    height: 28rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        height: 100%;
    }
}
.text {
    padding: 20rem 40rem;
    border-radius: 8rem;
    border: 1rem solid var(--border-grey-color);
    text-align: center;
    font-size: 12rem;
    line-height: 16rem;
    color: var(--text-color);
}
</style>